import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { createExpense, deleteOneExpense, getExpenses, getOneExpense, updateExpense, } from './actions';
var initialState = {
    expenses: { docs: [], meta: {} },
    expense: null,
    status: IStatus.IDLE,
};
var slice = createSlice({
    name: 'expenses',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        // CREATE ONE
        builder
            .addCase(createExpense.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(createExpense.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.expense = action.payload.data;
        })
            .addCase(createExpense.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // UPDATE ONE
        builder
            .addCase(updateExpense.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(updateExpense.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.expense = action.payload.data;
        })
            .addCase(updateExpense.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // GET ALL
        builder
            .addCase(getExpenses.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getExpenses.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.expenses = action.payload;
        })
            .addCase(getExpenses.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // GET ONE
        builder
            .addCase(getOneExpense.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getOneExpense.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.expense = action.payload.data;
        })
            .addCase(getOneExpense.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // DELETE ONE
        builder
            .addCase(deleteOneExpense.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deleteOneExpense.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.expenses.docs = state.expenses.docs.filter(function (expense) { return expense._id !== action.meta.arg.expenseId; });
        })
            .addCase(deleteOneExpense.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export default slice.reducer;
